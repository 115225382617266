import React from 'react';
import {useChecklistTasks} from "../Data/useProfilesLists";
import SubItemCheckbox from "./TaskCard/SubItemCheckbox";

interface IChecklistTasksSectionProps {
    selectedProfile: string
    listId: string
    taskId: string
}

const ChecklistTasksSection: React.FC<IChecklistTasksSectionProps> = ({selectedProfile, listId, taskId}) => {

    const {data: checklistItems, isLoading} = useChecklistTasks({selectedProfile, listId, taskId});

    if(isLoading || !checklistItems)
        return null;

    return (
        <>
            {checklistItems.map((subTask, index) => (
                <SubItemCheckbox
                    key={subTask.Id + index}
                    selectedProfile={selectedProfile}
                    listId={listId}
                    taskId={taskId}
                    subTask={subTask}
                    index={index}
                />
            ))}
        </>
    );
};

export default ChecklistTasksSection;