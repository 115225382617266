import React, {CSSProperties} from 'react';
import {ChecklistTaskItem, TaskItem} from "../../Data/TaskItem";
import {useAddItemForList, useAddOrUpdateChecklistForItem} from "../../Data/useProfilesLists";

interface IAddTaskDetailsBtnsRowProps {
    selectedProfile: string
    listId: string
    task :TaskItem
}

const ContainerStyle: CSSProperties = {
    display: "flex",
    minHeight: "0.8em",
    justifyContent: "flex-end",
    marginTop: "-.1em",
    paddingBottom: ".1em",
    zIndex: 4
}

const BtnStyle: CSSProperties = {
    fontSize: "0.7em",
    margin: 0,
    cursor: "pointer",
    marginLeft: '10px',
    lineHeight: 1
}

const AddTaskDetailsBtnsRow: React.FC<IAddTaskDetailsBtnsRowProps> = ({selectedProfile, task, listId}) => {

    const {mutateAsync: addItem} = useAddItemForList(selectedProfile);
    const {mutateAsync: addOrUpdateChecklistItem} = useAddOrUpdateChecklistForItem(selectedProfile);

    const handleAddDescription = async () => {
        if(!task.Description)
            task.Description = "_Description"
        await addItem({newTask: task, listId})
    }

    const handleAddChecklistClicked = async () => {
        await addOrUpdateChecklistItem({taskId: task.Id, listId, checklistTask: new ChecklistTaskItem()});
    }

    return (
        <div style={ContainerStyle}>
            {task.Description ? null : <label className={"card-child"} style={BtnStyle} onClick={handleAddDescription}>Description</label>}
            {task.ChecklistSubItems?.length ? null : <label className={"card-child"} style={BtnStyle} onClick={handleAddChecklistClicked}>Checklist</label>}
        </div>
    );
};

export default AddTaskDetailsBtnsRow;