import React from 'react';
import {useAddListForProfile, useProfilesLists, useSetListsForProfile} from "../Data/useProfilesLists";
import {MDBBtn, MDBContainer, MDBIcon} from "mdb-react-ui-kit";
import '../Styles/tasks.css';
import ListCard from "../Components/ListCard/ListCard";
import {DragDropContext, DropResult} from "react-beautiful-dnd";
import styled from "styled-components";
import {DroppableGio} from "../../../CommonComponents/DroppableGio";
import {TaskList} from "../Data/TaskList";
import {TaskItem} from "../Data/TaskItem";

interface ITasksListsProps {
    selectedProfile: string
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;

  overflow: auto;
`;

const StretchContainer = styled.div`
  display: flex;
  flex: 1;
  //height: 100vh;
`;

const ProfileTaskLists: React.FC<ITasksListsProps> = ({selectedProfile}) => {

    const {data: taskLists, isLoading} = useProfilesLists(selectedProfile);
    const {mutateAsync: addListForProfile} = useAddListForProfile(selectedProfile);
    const {mutateAsync: setLists} = useSetListsForProfile(selectedProfile);

    const onDragEnd = async (result: DropResult) => {
        console.log(result);
        const { destination, source, draggableId, type } = result;
        //source/destination .droppableId = column id (TaskList)

        if (!destination || !taskLists)
            return;

        if (destination.droppableId === source.droppableId && destination.index === source.index)
            return;


        if (type === 'column') {
            const index = taskLists.findIndex(x => x.Id === draggableId);
            const newColumns = [...taskLists];
            newColumns.splice(source.index, 1);
            newColumns.splice(destination.index, 0, taskLists[index]);

            await setLists(newColumns);
            return;
        }


        const startColumnIndex = taskLists.findIndex(x => x.Id === source.droppableId);
        const startColumn = taskLists[startColumnIndex];
        const finishColumnIndex = taskLists.findIndex(x => x.Id === destination.droppableId);
        const finishColumn = taskLists[finishColumnIndex];

        if (startColumn === finishColumn) {
            if(!startColumn || !finishColumn)
                return;
            // Moving within the same column
            // const newTaskIds = Array.from(startColumn.taskIds);
            // newTaskIds.splice(source.index, 1);
            // newTaskIds.splice(destination.index, 0, draggableId);

            const newTaskItems = [...startColumn.TaskItems];

            const taskIndex = newTaskItems.findIndex(x => x.Id === draggableId);
            if(taskIndex >= 0){
                const newItem = newTaskItems[taskIndex];
                newTaskItems.splice(source.index, 1);
                newTaskItems.splice(destination.index, 0, new TaskItem({...newItem}));
                taskLists[startColumnIndex] = new TaskList(startColumn);
            }

            const newColumn: TaskList = {
                ...startColumn,
                TaskItems: newTaskItems
            };

            const newTaskLists = [...taskLists];
            newTaskLists[startColumnIndex] = newColumn;

            await setLists(newTaskLists);
            return;
        }

        if(!finishColumn || !draggableId)
            return;

        // Moving from one list to another
        const startTasks = [...startColumn.TaskItems];
        startTasks.splice(source.index, 1);
        const newStartColumn = new TaskList( {
            ...startColumn,
            TaskItems: startTasks,
        });

        const taskIndex = startColumn.TaskItems.findIndex(x => x.Id === draggableId);
        const finishTasks = [...finishColumn.TaskItems];
        finishTasks.splice(destination.index, 0, startColumn.TaskItems[taskIndex]);
        const newFinishColumn = new TaskList({
            ...finishColumn,
            TaskItems: finishTasks,
        })

        const newTaskLists = [...taskLists];
        newTaskLists[startColumnIndex] = newStartColumn;
        newTaskLists[finishColumnIndex] = newFinishColumn;
        console.log("New StartColumn:", newStartColumn);

        await setLists(newTaskLists);
        return;
    }

    if(!taskLists || isLoading)
        return null;

    const handleAddListClicked = async () => {
        let nextTaskNum = `List #${taskLists.length + 1}`;
        for(let i = 1;; i++){
            if(taskLists.some(x => x.Name === nextTaskNum))
                nextTaskNum = `List #${i}`;
            else
                break;
        }

        const newListName = prompt("New List Name", nextTaskNum);
        if(!newListName)
            return;
        await addListForProfile({newListName});
    }

    return (

            <div className={"mt-3"} style={{display: "flex", flex: "1", flexDirection: "column"}}>

                {/*<MDBContainer tag="form" fluid className='justify-content-start align-items-center d-flex mb-2'>
                    <h3 style={{margin: 0}}>{selectedProfile}</h3>
                    <MDBBtn outline color="secondary" className={"ms-4"} size="sm" type='button' onClick={handleAddListClicked}>
                        Add List
                    </MDBBtn>
                </MDBContainer>*/}

                <DragDropContext onDragEnd={onDragEnd} >
                    <StretchContainer>
                        <DroppableGio droppableId={"all-columns"} direction={"horizontal"} type={"column"}>
                            {(provided) => (
                                <Container
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {taskLists.map((list, index) => (
                                        <ListCard key={list.Id} index={index} selectedProfile={selectedProfile} list={list}/>
                                    ))}
                                    {/*<MDBBtn floating tag='a' onClick={handleAddListClicked} color={"success"} className={"ms-3"}>
                                        <MDBIcon fas size={"lg"} icon='plus'/>
                                    </MDBBtn>*/}
                                    {provided.placeholder}
                                </Container>
                            )}
                        </DroppableGio>
                    </StretchContainer>
                </DragDropContext>
            </div>
    )
};

export default ProfileTaskLists;