import React from 'react';
import {useDeleteItemFromList, useDeleteListFromProfile} from "../../Data/useProfilesLists";
import DeleteBtn from "../DeleteBtn";

interface IDeleteTaskCardBtnProps {
    selectedProfile: string
    listId: string,
    taskId: string
}

const DeleteTaskCardBtn: React.FC<IDeleteTaskCardBtnProps> = ({selectedProfile, listId, taskId}) => {
    const {mutateAsync: deleteItem} = useDeleteItemFromList(selectedProfile);

    const handleButtonClicked = async () => {
        await deleteItem({listId, taskId});
    }

    return (
        <DeleteBtn containerClassName={"card-child"} onClick={handleButtonClicked}/>
    );
};

export default DeleteTaskCardBtn;