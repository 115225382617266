import React, {CSSProperties, useCallback, useEffect, useRef, useState} from 'react';
import OverlayTextArea from "./OverlayTextArea";

interface IWrappedSingleLineTextAreaProps extends Omit<React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, "onBlur"> {
    text: string
    setText: (value: string | ((oldValue: string) => string)) => void
    onEnterPressed?: (value: string) => void
    onBlur?: (event?: React.FocusEvent<HTMLTextAreaElement>) => void
    onTabPressed?: () => void
}

const TextAreaStyle: CSSProperties = {
    border: "none",
    background: "white",
    resize: "none",
    width: "100%",
    overflow: "hidden",
    lineHeight: "1.2",
}

const WrappedSingleLineTextArea: React.FC<IWrappedSingleLineTextAreaProps> = ({text, setText, onBlur, onEnterPressed, onTabPressed, style, ...textAreaProps}) => {

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [scrollHeight, setScrollHeight] = useState(0);

    useEffect(() => {
        setScrollHeight(textareaRef.current?.scrollHeight ?? 0);
    }, [textareaRef.current?.scrollHeight])

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'; // Reset the height to auto
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set the new height
        }
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [text]);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value.replace(/\n*/g, ''))
    }, []);

    const handleKeyUp = useCallback(async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {

    }, []);

    const handleKeyDown = useCallback(async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if(event.key === "Tab" && !event.shiftKey) {
            if(onTabPressed)
                onTabPressed();
            return;
        }

        const target = (event.target as HTMLInputElement)
        if(event.key === "Enter" && !event.shiftKey) {
            setText(x => x?.replace(/\n*/g, ''));
            const value = target.value.replace(/\n*/g, '');
            if(value){
                target.blur();
                if(onBlur)
                    await onBlur();
            }
            if(onEnterPressed)
                onEnterPressed(value);
        }
    }, []);

    return (
        <OverlayTextArea focusInput={() => textareaRef.current?.focus()} onBlur={onBlur} height={scrollHeight} style={{width: "100%"}}>
            <textarea
                ref={textareaRef}
                rows={1}
                value={text}
                onChange={handleChange}
                style={{...TextAreaStyle, ...style}}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
                {...textAreaProps}
            />
        </OverlayTextArea>
    )
};

export default WrappedSingleLineTextArea;