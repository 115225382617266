import React, {CSSProperties} from 'react';
import DescriptionTextArea from "./DescriptionTextArea";
import SubItemCheckbox from "./SubItemCheckbox";
import {MDBCardBody} from "mdb-react-ui-kit";
import {TaskItem} from "../../Data/TaskItem";
import {TaskList} from "../../Data/TaskList";
import {useAddItemForList} from "../../Data/useProfilesLists";
import ChecklistTasksSection from "../ChecklistTasksSection";

interface ITaskCardBodyProps {
    selectedProfile: string
    list: TaskList
    task: TaskItem
}

const CardBodyStyle: CSSProperties = {
    padding: 0,
    paddingTop: "7px",
    paddingLeft: "7px",
    paddingRight: "7px"
}

const TaskCardBody: React.FC<ITaskCardBodyProps> = ({selectedProfile, list, task}) => {
    const {mutateAsync: addItem} = useAddItemForList(selectedProfile);

    const handleUpdateItemForList = async (listId: string, task: TaskItem) => {
        if(!task.Name)
            return;
        await addItem({newTask: task, listId})
    }
    return (
        <MDBCardBody style={CardBodyStyle}>
            {task.Description ? (
                <DescriptionTextArea
                    item={task}
                    list={list}
                    updateItemForList={handleUpdateItemForList}
                />
            ) : null}
            {task.ChecklistSubItems?.length ? (
                <div style={{display:"flex", flexDirection:"column",}}>
                    <ChecklistTasksSection selectedProfile={selectedProfile} listId={list.Id} taskId={task.Id}/>
                </div>
            ) : null}
        </MDBCardBody>
    );
};

export default TaskCardBody;