import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import localforage from "localforage";

export const SelectedProfileQueryKey = "SelectedProfile";

export function useSelectedProfile(){
    return useQuery<string>([SelectedProfileQueryKey], async () => {
        const storageItem = await localforage.getItem<string>(SelectedProfileQueryKey);
        if(!storageItem)
            return "My Tasks";
        return storageItem as string;
    });
}


export function useMutateSelectedProfile(){
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (selectedProfile: string) => {
            await localforage.setItem(SelectedProfileQueryKey, selectedProfile);
        },
        // When mutate is called:
        onMutate: async (variables) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: [SelectedProfileQueryKey] })

            // Optimistically update to the new value
            queryClient.setQueryData<string>([SelectedProfileQueryKey], old => {
                return variables;
            });
        },
    });
}