import React from 'react';
import {useDeleteListFromProfile} from "../../Data/useProfilesLists";
import DeleteBtn from "../DeleteBtn";

interface IDeleteListCardBtnProps {
    selectedProfile: string
    listId: string
}

const DeleteListCardBtn: React.FC<IDeleteListCardBtnProps> = ({selectedProfile, listId}) => {
    const {mutateAsync: deleteListForProfile} = useDeleteListFromProfile(selectedProfile);

    const handleButtonClicked = async () => {
        if (window.confirm('Deleting this list will delete all items within it. Do you want to delete this list?'))
            await deleteListForProfile(listId);
    }

    return (
        <DeleteBtn containerClassName={"list-child"} onClick={handleButtonClicked}/>
    );
};

export default DeleteListCardBtn;