import React, {useState} from 'react';
import {MDBCardHeader} from "mdb-react-ui-kit";
import DeleteListCardBtn from "./DeleteListCardBtn";
import WrappedSingleLineTextArea from "../../../../CommonComponents/WrappedSingleLineTextArea";
import {useSetListName} from "../../Data/useProfilesLists";

interface IListCardHeaderProps {
    selectedProfile: string
    listId: string
    listName: string
}

const ListCardHeader: React.FC<IListCardHeaderProps> = ({selectedProfile, listId, listName, ...rest}) => {

    const [editedListName, setEditedListName] = useState(listName);
    const {mutateAsync: updateListName} = useSetListName(selectedProfile, listId);

    const handleSaveListName = async () => {
        await updateListName(editedListName);
    }
    const handleSaveListNameFromInput = async (value: string) => {
        await updateListName(value);
    }

    return (
        <MDBCardHeader className={"p-2"} {...rest}>
            <WrappedSingleLineTextArea
                text={editedListName}
                setText={setEditedListName}
                style={{background: "transparent"}}
                onBlur={handleSaveListName}
                onEnterPressed={handleSaveListNameFromInput}
                onTabPressed={handleSaveListName}
            />
            <DeleteListCardBtn selectedProfile={selectedProfile} listId={listId}/>
        </MDBCardHeader>
    );
};

export default ListCardHeader;