import React from 'react';
import './App.css';
import {MDBContainer} from "mdb-react-ui-kit";
import HomeScreen from "./Features/Home/HomeScreen";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 20 * 60 * 1000,
            cacheTime: 60 * 60 * 1000
        },
        mutations: {
            cacheTime: 60 * 60 * 1000
        },
    }
});

/*

To fix the input for drag can do two things
1. Have overlay, when clicked hide it, when unfocused input show it.
2. have different component when clicked hide and actually it's just the same thing lol just harder
do it on mouse up, it's the best solution can be dragged, can highlight and click anywhere in the input once focussed.
*/

function App() {
  return (
      <QueryClientProvider client={queryClient}>
          <MDBContainer fluid className={"p-0"} style={{height: "100vh", display: "flex", flexDirection: "column"}}>
            <HomeScreen/>
          </MDBContainer>
          <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
  );
}

export default App;
