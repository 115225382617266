import React, {CSSProperties} from 'react';
import {MDBCardBody, MDBCardTitle} from "mdb-react-ui-kit";
import TaskCard from "../TaskCard/TaskCard";
import {TaskList} from "../../Data/TaskList";
import AddTaskItemBtn from "./AddTaskItemBtn";
import {DroppableGio} from "../../../../CommonComponents/DroppableGio";

interface IListCardBodyProps {
    selectedProfile: string
    list: TaskList
}

const ContainerStyle: CSSProperties = {display: "flex", flexDirection: "column"}

const ListCardBody: React.FC<IListCardBodyProps> = ({selectedProfile, list}) => {
    const taskItems = list.TaskItems;

    return (
            <DroppableGio droppableId={list.Id} type={"task"}>
                {provided => (
                    // @ts-ignore
                    <MDBCardBody ref={provided.innerRef} {...provided.droppableProps} className={"ps-2 pe-2 pb-2"} style={{paddingTop: ".25rem"}}>
                        <div style={ContainerStyle}>
                            {/*{taskItems.length === 0 ? <MDBCardTitle style={{fontSize: "0.8em"}}>Add an item to get started!</MDBCardTitle> : null}*/}
                                {taskItems.map((task, index) => {
                                    return <TaskCard key={task.Id} selectedProfile={selectedProfile} list={list} task={task} index={index}/>
                                })}
                                {provided.placeholder}
                            <AddTaskItemBtn selectedProfile={selectedProfile} listId={list.Id}
                                            taskItemsCount={list.TaskItems.length}/>
                        </div>
                    </MDBCardBody>
                )}
            </DroppableGio>
    );
};

export default ListCardBody;