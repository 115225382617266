import React, {CSSProperties} from 'react';
import DeleteTaskCardBtn from "./DeleteTaskCardBtn";
import NameTextArea from "./NameTextArea";
import AddTaskDetailsBtnsRow from "./AddTaskDetailsBtnsRow";
import {MDBCardHeader} from "mdb-react-ui-kit";
import {TaskItem} from "../../Data/TaskItem";
import {TaskList} from "../../Data/TaskList";
import {useAddItemForList, useDeleteItemFromList} from "../../Data/useProfilesLists";

interface ITaskCardHeaderProps {
    selectedProfile: string
    list: TaskList
    task: TaskItem
}

const headerStyle: CSSProperties = {
    padding: 0,
    paddingTop: "7px",
    paddingLeft: "7px",
    paddingRight: "7px",
    display: "flex",
    flexDirection: "column",
}

const TaskCardHeader: React.FC<ITaskCardHeaderProps> = ({selectedProfile, list, task}) => {
    const {mutateAsync: addItem} = useAddItemForList(selectedProfile);
    const {mutateAsync: deleteItem} = useDeleteItemFromList(selectedProfile);

    const taskHasDetails = ((task.Description && task.Description.length > 0) || (task.ChecklistSubItems && task.ChecklistSubItems.length > 0));

    const handleAddItemForList = async (listId: string, currentTasksCount: number) => {
        const newTask = new TaskItem({Name: "_New Item #" + (currentTasksCount + 1)});
        await addItem({newTask, listId})
    }

    const handleUpdateItemForList = async (listId: string, task: TaskItem) => {
        if(!task.Name)
            return;
        await addItem({newTask: task, listId})
    }


    return (
        <MDBCardHeader style={{...headerStyle, border: taskHasDetails ? undefined : "none"}}>
            <DeleteTaskCardBtn selectedProfile={selectedProfile} listId={list.Id} taskId={task.Id}/>
            <NameTextArea
                item={task}
                list={list}
                addItemForList={handleAddItemForList}
                updateItemForList={handleUpdateItemForList}
                deleteItem={deleteItem}
            />
            <AddTaskDetailsBtnsRow selectedProfile={selectedProfile} listId={list.Id} task={task}/>
        </MDBCardHeader>
    );
};

export default TaskCardHeader;