import React, {useEffect, useRef, useState} from 'react';
import OverlayTextArea from "../../../../CommonComponents/OverlayTextArea";

interface ITaskTextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement>{
    initialValue: string | undefined
    trimNewLineOnEnter?: boolean
}

const TaskTextArea: React.FC<ITaskTextAreaProps> = ({initialValue, trimNewLineOnEnter, onKeyUp, style, ...rest}) => {

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [text, setText] = useState(initialValue)
    const [scrollHeight, setScrollHeight] = useState(0);

    useEffect(() => {
        setScrollHeight(textareaRef.current?.scrollHeight ?? 0);
    }, [textareaRef.current?.scrollHeight])

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'; // Reset the height to auto
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set the new height
        }
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [text]);
    // console.log("document.activeElement", document.activeElement)
    return (
        <OverlayTextArea focusInput={() => textareaRef.current?.focus()} height={scrollHeight} onBlur={rest.onBlur}>
            <textarea
                className={"resize-when-focussed"}
                ref={textareaRef}
                value={text}
                onChange={event => {
                    setText(event.target.value)
                }}
                rows={1}
                style={{
                    border: "none",
                    background: "white",
                    // textAlign: "center",
                    // resize: document.activeElement?.id === textareaRef.current?.id ? "vertical" : "none",
                    width: "100%",
                    overflow: "hidden",
                    lineHeight: "1.2",
                    ...style
                }}
                onKeyUp={async (event) => {
                    if(event.key === "Enter" && !event.shiftKey ) {
                        if(trimNewLineOnEnter)
                            setText(x => x?.replace(/^\n+|\n+$/g, ''));
                    }
                    if(onKeyUp)
                        onKeyUp(event);
                }}
                {...rest}
            />
        </OverlayTextArea>
    );
};

export default TaskTextArea;