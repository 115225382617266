import React, {useEffect, useState} from 'react';
import {migrateLocalStorage, useAddProfile, useArchiveProfile, useDeleteProfile, useProfiles} from "./Data/useProfiles";
import {
    MDBBtn, MDBCollapse,
    MDBContainer,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBIcon,
    MDBNavbar,
    MDBNavbarItem,
    MDBNavbarNav,
    MDBNavbarToggler
} from "mdb-react-ui-kit";
import {useMutateSelectedProfile, useSelectedProfile} from "./Data/useSelectedProfile";
import ProfileTaskLists from "../Tasks/Screens/ProfileTaskLists";
import {useAddListForProfile, useProfilesLists} from "../Tasks/Data/useProfilesLists";
import {TaskProfile} from "../Tasks/Data/TaskProfile";
/*
const messageListener = function(event: MessageEvent<any>) {
    if (event.data.type && (event.data.type === "FROM_EXTENSION")) {
        console.log("Message received in web page - ", event.data.message);
        console.log(event);
        alert(event.data.message);
    }
}*/

const HomeScreen: React.FC = () => {
    const {data:selectedProfile} = useSelectedProfile();
    const {mutateAsync: updateSelectedProfile} = useMutateSelectedProfile();
    const {data:profiles, isLoading} = useProfiles();
    const {mutateAsync: archiveProfile} = useArchiveProfile();
    const {mutateAsync: addProfile} = useAddProfile();
    const {mutateAsync: addListForProfile} = useAddListForProfile(selectedProfile ?? "My Tasks");
    const {mutateAsync: deleteProfile} = useDeleteProfile();

    const {data: taskLists} = useProfilesLists(selectedProfile ?? "My Tasks");

    const [showArchived, setShowArchived] = useState(false);

    useEffect(() => {
        (async () => {
            const success = await migrateLocalStorage();
            // if(success)
            //     alert("should be set")
        })();
    })

/*

    useEffect(() => {
        console.log("Adding event listener")
        // Add an event listener to listen for messages from the extension
        window.addEventListener("message", messageListener);

        // Clean up the event listener when the component unmounts
        return () => {
            console.log("Removing event listener")
            window.removeEventListener('message', messageListener);
        };
    }, []);
*/

    if(isLoading || !profiles)
        return null;

    const activeProfiles = profiles.filter(x => !x.Archived);
    const archivedProfiles = showArchived ? profiles.filter(x => x.Archived) : [];

    const handleAddProfileClicked = async () => {
        const newProfile = prompt("New Profile Name", `Profile #${profiles.length + 1}`);
        if(!newProfile)
            return;
        await updateSelectedProfile(newProfile);
        await addProfile(newProfile);
    }

    const handleAddListClicked = async () => {
        let nextTaskNum = `List #${(taskLists?.length??0) + 1}`;
        for(let i = 1;; i++){
            if(taskLists && taskLists.some(x => x.Name === nextTaskNum))
                nextTaskNum = `List #${i}`;
            else
                break;
        }

        const newListName = prompt("New List Name", nextTaskNum);
        if(!newListName)
            return;
        await addListForProfile({newListName});
    }

    const handleProfileClicked = async (profile: TaskProfile) => {
        if(profile.Name !== selectedProfile)
            await updateSelectedProfile(profile.Name);
    }

    const handleArchivePressed = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, profile: TaskProfile, archive: boolean) => {
        event.stopPropagation();
        event.preventDefault();
        await archiveProfile({profileName: profile.Name, archive});
    }

    const handleShowHideArchivePressed = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
        setShowArchived(x => !x);
    }

    const handleDeleteProfile = async () => {
        const willDelete = window.confirm(`Deleting profile ${selectedProfile} will delete all lists and tasks associated with it. Are you sure you want to continue?`)
        if(willDelete && selectedProfile) {
            await deleteProfile(selectedProfile);
            if(profiles){
                for (const profile of profiles) {
                    if(profile.Name !== selectedProfile){
                        await updateSelectedProfile(profile.Name);
                        break;
                    }
                }
            }
        }
    }

    return (
        <>
            <MDBNavbar expand={'sm'} light bgColor='light'>
                <MDBContainer fluid>
                    <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
                        <MDBNavbarItem>
                            <MDBDropdown>
                                <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                                    {selectedProfile}
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <>
                                        {activeProfiles?.map(profile => (
                                            <MDBDropdownItem
                                                key={profile.Id}
                                                disabled={profile.Name === selectedProfile}
                                                link={true}
                                                onClick={() => handleProfileClicked(profile)}
                                            >
                                                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                                    {profile.Name}
                                                    <div onClick={(e) => handleArchivePressed(e, profile, true)}>
                                                        <MDBIcon style={{marginLeft: "1rem"}} icon='box-archive' fas />
                                                    </div>
                                                </div>
                                            </MDBDropdownItem>
                                        ))}

                                        <MDBDropdownItem divider />
                                        <MDBDropdownItem key={"add"} link={true} onClick={handleAddProfileClicked} >
                                            <div style={{justifyContent: "space-between", display: "flex", alignItems: "center"}}>
                                                Add Profile
                                                <MDBIcon style={{marginLeft: "1rem"}} icon='plus' fas />
                                            </div>
                                        </MDBDropdownItem>
                                        <MDBDropdownItem divider />
                                        <MDBDropdownItem key={"ShowHideArchived"} link={true} onClick={handleShowHideArchivePressed} >
                                            <div style={{justifyContent: "space-between", display: "flex", alignItems: "center"}}>
                                                {showArchived ? "Hide" : "Show"} Archived
                                                <MDBIcon style={{marginLeft: "1rem"}} icon={showArchived ? "eye-slash" : "eye"} fas />
                                            </div>
                                        </MDBDropdownItem>

                                        {archivedProfiles.sort((first, second) => first.Name < second.Name ? -1 : 1)?.map(profile => (
                                            <MDBDropdownItem
                                                key={profile.Id}
                                                disabled={profile.Name === selectedProfile}
                                                link={true}
                                                onClick={() => handleProfileClicked(profile)}
                                            >
                                                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                                    {profile.Name}
                                                    <div onClick={(e) => handleArchivePressed(e, profile, false)}>
                                                        <MDBIcon style={{marginLeft: "1rem"}} icon='box-open' fas />
                                                    </div>
                                                </div>
                                            </MDBDropdownItem>
                                        ))}
                                    </>
                                </MDBDropdownMenu>
                            </MDBDropdown>

                        </MDBNavbarItem>

                        <MDBContainer tag="form" fluid className='justify-content-start align-items-center d-flex'>
                            <MDBBtn outline color="secondary" size="sm" type='button' onClick={handleAddListClicked}>
                                Add List
                            </MDBBtn>
                        </MDBContainer>

                        {/*<MDBContainer tag="form" fluid className='justify-content-start align-items-center d-flex'>
                            <MDBBtn outline color="secondary" size="sm" type='button' onClick={handleAddProfileClicked}>
                                Add Profile
                            </MDBBtn>
                        </MDBContainer>*/}

                    </MDBNavbarNav>

                    <div className='d-flex input-group w-auto' style={{cursor: "pointer"}}>
                        <div onClick={handleDeleteProfile}>
                            <MDBIcon style={{marginRight: "1rem"}} size={"2x"} color={"dark"} icon='trash-alt' far />
                        </div>
                    </div>

                </MDBContainer>
            </MDBNavbar>
            {selectedProfile ? <ProfileTaskLists selectedProfile={selectedProfile}/> : null}
        </>
    );
};

export default HomeScreen;