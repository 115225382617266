import React, {useEffect, useRef, useState} from 'react';
import {TaskItem} from "../../Data/TaskItem";
import {TaskList} from "../../Data/TaskList";
import WrappedSingleLineTextArea from "../../../../CommonComponents/WrappedSingleLineTextArea";

interface INameTextAreaProps {
    item: TaskItem
    list: TaskList
    addItemForList: (listId: string, currentTasksCount: number) => Promise<void>
    updateItemForList: (listId: string, task: TaskItem) => Promise<void>
    deleteItem: (variables: {taskId: string, listId: string}) => Promise<void>
}

const NameTextArea: React.FC<INameTextAreaProps> = ({item, updateItemForList, list, addItemForList, deleteItem}) => {

    const isNewItem = item.Name.startsWith("_New Item");
    const initialName = isNewItem ? undefined : item.Name;
    const placeholder = isNewItem ? item.Name.substring(1) : undefined;
    const [text, setText] = useState(initialName ?? "")
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'; // Reset the height to auto
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set the new height
        }
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [text]);

    const handleBlur = async () => {
        if(text && text.length > 0){
            item.Name = text;
            await updateItemForList(list.Id, item);
        }
        else {
            await deleteItem({taskId: item.Id, listId: list.Id});
        }
    }

    const handleEnterPressed = async (value: string) => {
        console.log("value for enter pressed:", value)
        if(value) {
            item.Name = value;
            await updateItemForList(list.Id, item);
            await addItemForList(list.Id, list.TaskItems.length);
        }
        else
            await deleteItem({taskId: item.Id, listId: list.Id});
    }

    const handleTabPressed = async () => {
        if(!item.Description){
            item.Description = "_Description";
            await updateItemForList(list.Id, item);
        }
    }

    return (
        <WrappedSingleLineTextArea
            text={text}
            setText={setText}
            placeholder={placeholder}
            autoFocus={isNewItem}
            onBlur={handleBlur}
            onEnterPressed={handleEnterPressed}
            onTabPressed={handleTabPressed}
            style={{fontSize: "0.93em"}}
        />
    )
};

export default NameTextArea;