import React from 'react';
import {TaskItem} from "../../Data/TaskItem";
import {TaskList} from "../../Data/TaskList";
import TaskTextArea from "./TaskTextArea";
import OverlayInput from "../../../../CommonComponents/OverlayInput";

interface IDescriptionTextAreaProps {
    item: TaskItem
    list: TaskList
    updateItemForList: (listId: string, task: TaskItem) => Promise<void>
}

const DescriptionTextArea: React.FC<IDescriptionTextAreaProps> = ({item, updateItemForList, list}) => {

    const isNewItem = item.Description!.startsWith("_Description");
    const initialDescription = isNewItem ? undefined : item.Description;
    const placeholder = isNewItem ? item.Description!.substring(1) : undefined;

    return (
        <TaskTextArea
            style={{fontSize: ".8em", color: "rgb(87 86 86)"}}
            placeholder={placeholder}
            initialValue={initialDescription}
            autoFocus={isNewItem}
            onKeyUp={async (event) => {
                /*if(event.key === "Enter" && !event.shiftKey) {
                    (event.target as HTMLInputElement).blur();
                    await addItemForList(list.Id, list.TaskItems.length);
                }*/
            }}
            onBlur={async (event) => {
                item.Description = event.target.value.replace(/^\n+|\n+$/g, '');
                await updateItemForList(list.Id, item);
            }}
            // style={{textAlign: "start"}}
        />
    );
};

export default DescriptionTextArea;