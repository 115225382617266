export class Crypt {
    public static GenerateString(length: number = 5) {
        const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZ';

        let result = '';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ )
            result += characters.charAt(Math.floor(Math.random() * charactersLength));

        return result;
    }
    public static GetUID(): string {
        // @ts-ignore
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
}