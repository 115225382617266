import React, {CSSProperties} from 'react';
import {MDBCard} from "mdb-react-ui-kit";
import ListCardHeader from "./ListCardHeader";
import ListCardBody from "./ListCardBody";
import {TaskList} from "../../Data/TaskList";
import styled from "styled-components";
import {Draggable} from "react-beautiful-dnd";

interface IListCardProps {
    selectedProfile: string
    list: TaskList
    index: number
}

const CardStyle: CSSProperties = {
    // minHeight: "500px",
    // maxWidth: "400px",
    width: "15vw",
    backgroundColor: "rgb(198 213 228)",
    marginRight: "15px",
    marginLeft: "15px"
}

const Container = styled.div`
`;

const ListCard: React.FC<IListCardProps> = React.memo(({selectedProfile, list, index}) => {
    return (
        <Draggable draggableId={list.Id} index={index}>
            {(provided) => (
                <Container {...provided.draggableProps} ref={provided.innerRef}>
                    <MDBCard className={"list-container"} alignment='center' style={CardStyle}>
                        <ListCardHeader {...provided.dragHandleProps} selectedProfile={selectedProfile} listId={list.Id} listName={list.Name}/>
                        <ListCardBody selectedProfile={selectedProfile} list={list}/>
                    </MDBCard>
                </Container>
            )}
        </Draggable>
    );
})

export default ListCard;